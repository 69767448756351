import React from "react"
import { LangContext } from "../components/globals/lang-context"
// import 'whatwg-fetch'

import Layout from "../components/layout"

import {
  Intro,
  Jumbo,
  Services,
  Methods,
  ContactForm,
} from "../components/sections"

// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <LangContext.Consumer>
      {({ lang }) => (
        <>
          <SEO lang={lang} title="Home" />
          <Jumbo lang={lang} />
          <Intro lang={lang} />
          <div id="serv" />
          <Services lang={lang} />
          <div id="method" />
          <Methods lang={lang} />
          <ContactForm lang={lang} />
          <div id="contact" />
        </>
      )}
    </LangContext.Consumer>
  </Layout>
)

export default IndexPage
